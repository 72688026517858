import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Event } from "../../services/calendar";
import { useConfiguration } from "../../services/firebase";
import { Attendee, UserInviteLevels } from "../../types";
import {
  getAllAttendeesEmails,
  getAllAttendeesNormalized,
} from "../../services/utils";
import { searchUsers } from "../../services/jira-api";

interface AttendeesContextProviderProps {
  children: React.ReactNode;
  events: Event[];
}

interface AttendeesContextData {
  attendees: Record<string, Attendee>;
  fetchAllUers: boolean;
}

const AttendeesContext = createContext<AttendeesContextData>({
  attendees: {},
  fetchAllUers: false,
});

export function AttendeesContextProvider({
  children,
  events,
}: AttendeesContextProviderProps) {
  const [attendees, setAttendees] = useState<Record<string, Attendee>>({});
  const { data } = useConfiguration();

  useEffect(() => {
    if (events.length) {
      setAttendees(getAllAttendeesNormalized(events));
    }
  }, [events]);

  const getAttendeeDetails = async (email: string) => {
    const searchResult = await searchUsers(email);
    let attendeeData: Attendee;
    if (searchResult.length) {
      const [{ accountId, displayName }] = searchResult;
      attendeeData = {
        accountId,
        displayName,
        email,
      };
    } else {
      attendeeData = {
        displayName: email,
        email,
      };
    }
    setAttendees((prev) => {
      return {
        ...prev,
        [email]: attendeeData,
      };
    });
  };

  const fetchAllUers = useMemo(
    () => data?.userInvitationLevel === UserInviteLevels.AllUsers,
    [data]
  );

  useEffect(() => {
    const allEmails = getAllAttendeesEmails(events);
    const attendeeDetailsRequests = allEmails.map(getAttendeeDetails);
    void Promise.all(attendeeDetailsRequests);
  }, [events]);

  const attendeesContext = useMemo(
    () => ({ attendees, fetchAllUers }),
    [attendees, fetchAllUers]
  );

  return (
    <AttendeesContext.Provider value={attendeesContext}>
      {children}
    </AttendeesContext.Provider>
  );
}

export function useAttendeesContext() {
  const context = useContext(AttendeesContext);
  if (!context) {
    throw new Error(
      "Attendees context not initialized, probably context provider is missing"
    );
  }
  return context;
}
