export interface OauthResponse {
  code: string;
  scope: string;
  error: string;
}

export interface JSONResponse {
  message: string;
}

export interface JiraContext {
  jira: {
    issue: {
      key: string;
    };
    project: {
      key: string;
      id: string;
    };
  };
}

export interface ApplicationContext {
  googleAPIClientId: string;
  firebaseToken: string;
  clientKey: string;
  userAccountId: string;
  scopes: string;
  hostBaseUrl: string;
  addonKey: string;
  isJiraAdmin: boolean;
  isEmailAccessScopeEnabled: boolean;
  displayUrl?: string;
  displayUrlServicedeskHelpCenter?: string;
  baseUrl: string;
}

export interface Configuration {
  isAnalyticsEnabled: boolean;
  userInvitationLevel: UserInviteLevels;
}

export enum UserInviteLevels {
  AllUsers = "allUsers",
  UsersPreference = "usersPreference",
}

export interface User {
  accountId: string;
  accountType: string;
  avatarUrls: {
    [key: string]: string;
  };
  displayName: string;
  emailAddress: string;
}

export interface Attendee {
  accountId?: string;
  displayName: string;
  avatar?: JSX.Element;
  email?: string;
}

export interface FetchedAttendee {
  email: string;
}

export interface DescriptionAutomationData {
  fields: string[];
  wasSet: boolean;
}

export interface JiraField {
  id: string;
  name: string;
  schema: {
    type: string;
    system: string;
    custom?: string;
  };
}

export interface JiraProject {
  projectTypeKey: string;
}
