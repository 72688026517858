import React, { useState, useEffect, useCallback, useMemo } from "react";
import { CreatableSelect } from "@atlaskit/select";
import Avatar from "@atlaskit/avatar";
import { searchUsers } from "../services/jira-api";
import { Attendee, User } from "../types";
import { useApplicationContext } from "../screens/Providers/ApplicationContextProvider";
import { validateEmail } from "../services/utils";

type UserOptionProperties = Omit<UserOption, "value" | "isNotCustom">;

const UserSelectOption = ({
  label,
  avatar,
  showAvatar,
}: UserOptionProperties) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {showAvatar && <div style={{ marginLeft: "2px" }}>{avatar}</div>}
      <div style={{ marginLeft: showAvatar ? "8px" : "0" }}>{label}</div>
    </div>
  );
};

interface UserPickerProps {
  attendees: Attendee[];
  setAttendees: (attendees: Attendee[]) => void;
  fetchAllUers: boolean;
}

interface UserOption {
  label: string;
  value: string;
  avatar?: JSX.Element;
  email?: string;
  showAvatar: boolean;
  isNotCustom: boolean;
}

const UserPicker = ({
  setAttendees,
  attendees,
  fetchAllUers,
}: UserPickerProps) => {
  const [users, setUsers] = useState<User[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { userAccountId } = useApplicationContext();

  const getAndSetUsers = (query: string) => {
    searchUsers(query)
      .then((users) => {
        setUsers(
          fetchAllUers ? users : users.filter((user) => user.emailAddress)
        );
      })
      .catch(console.error);
  };

  useEffect(() => getAndSetUsers(searchQuery), [searchQuery]);

  const userOptions = useMemo(
    () =>
      users
        .filter(
          (user) =>
            user.accountType === "atlassian" && user.accountId !== userAccountId
        )
        .map((user) => {
          return {
            label: user.displayName,
            value: user.accountId,
            avatar: (
              <Avatar
                src={user.avatarUrls["24x24"]}
                name={user.displayName}
                size="small"
                appearance="circle"
              />
            ),
            showAvatar: true,
            isNotCustom: true,
            email: user.emailAddress,
          };
        }),
    [userAccountId, users]
  );

  const renderUserOption = useCallback(
    (option: UserOption) => (
      <UserSelectOption
        label={option.label}
        avatar={option.avatar}
        key={option.value}
        showAvatar={option.showAvatar}
      />
    ),
    []
  );

  return (
    <CreatableSelect
      options={userOptions}
      maxMenuHeight={120}
      placeholder="Select guest or enter email"
      value={attendees.map((attendee) => ({
        value: (attendee.accountId || attendee.email) as string,
        label: attendee.displayName,
        avatar: attendee.avatar,
        email: attendee.email,
        showAvatar: false,
        isNotCustom: true,
      }))}
      onChange={(data) =>
        setAttendees(
          data.map(({ label, value, avatar, email, isNotCustom }) => {
            return {
              accountId: value,
              displayName: label,
              avatar,
              email: isNotCustom ? email : value,
            };
          })
        )
      }
      formatOptionLabel={renderUserOption}
      isMulti
      onInputChange={setSearchQuery}
      isValidNewOption={validateEmail}
    />
  );
};

export default UserPicker;
