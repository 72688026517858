import SectionMessage, {
  SectionMessageAction,
} from "@atlaskit/section-message";
import React, { ReactNode, useRef, useState } from "react";
import { GoogleSignInButton } from "../components/google/GoogleSignInButton";
import { getErrorMessage } from "../services/utils";
import { LoadingPage } from "../components/LoadingPage";
import { authorize } from "../services/oauth";
import { useScript } from "../services/hooks";
import { OauthResponse } from "../types";
import { useFeedbackContext } from "../components/FeedbackProvider";
import { useAnalyticsContext } from "./Providers/AnalyticsProvider";
import { useApplicationContext } from "./Providers/ApplicationContextProvider";

interface GoogleOAuthProviderProps {
  children: ReactNode;
}

function GoogleOAuthProvider({ children }: GoogleOAuthProviderProps) {
  const { status } = useScript("https://accounts.google.com/gsi/client");
  if (status === "idle") {
    return <LoadingPage />;
  } else if (status === "ready") {
    return <>{children}</>;
  } else {
    return (
      <SectionMessage appearance="error" title="Error occurred">
        <p>Failed to load resource: https://accounts.google.com/gsi/client</p>
      </SectionMessage>
    );
  }
}

export function SignInScreen() {
  return (
    <GoogleOAuthProvider>
      <GoogleSignIn />
    </GoogleOAuthProvider>
  );
}

function GoogleSignIn() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { setIsFeedbackOpen } = useFeedbackContext();
  const { googleAPIClientId, scopes } = useApplicationContext();
  const analytics = useAnalyticsContext();

  function callback(res: unknown): void {
    setLoading(true);
    void authorize(res as OauthResponse, scopes).catch((error) => {
      setLoading(false);
      setError(getErrorMessage(error));
    });
  }

  const client = useRef(
    google.accounts.oauth2.initCodeClient({
      client_id: googleAPIClientId,
      scope: scopes,
      ux_mode: "popup",
      callback,
    })
  );

  function onClick() {
    void analytics.activeUse.connectAccountClicked();
    return client.current.requestCode();
  }

  if (loading) {
    return <LoadingPage />;
  }

  if (!error) {
    return (
      <SectionMessage title="Issue Events requires your permission">
        <div className="content">
          <GoogleSignInButton onClick={onClick} />
        </div>
      </SectionMessage>
    );
  } else {
    return (
      <SectionMessage
        appearance="error"
        title="Error occurred"
        actions={[
          <SectionMessageAction key="try-again" onClick={onClick}>
            Try Again
          </SectionMessageAction>,
          <SectionMessageAction
            key="support"
            onClick={() => setIsFeedbackOpen(true)}
          >
            Support
          </SectionMessageAction>,
        ]}
      >
        <p>{error}</p>
      </SectionMessage>
    );
  }
}
