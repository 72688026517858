import React, {
  Dispatch,
  FC,
  FormEvent,
  Fragment,
  KeyboardEvent,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Button from "@atlaskit/button";
import LoadingButton from "@atlaskit/button/loading-button";
import { Checkbox } from "@atlaskit/checkbox";
import TextField from "@atlaskit/textfield";
import { DatePicker, TimePicker } from "@atlaskit/datetime-picker";
import Select, { OptionType, ValueType } from "@atlaskit/select";
import styled from "styled-components";
import Form, { CheckboxField, Field, FormSection } from "@atlaskit/form";
import ArrowLeftCircleIcon from "@atlaskit/icon/glyph/arrow-left-circle";
import { isBefore } from "date-fns";
import { getWeekStartByLocale } from "weekstart";
import Tooltip from "@atlaskit/tooltip";
import EditorPanelIcon from "@atlaskit/icon/glyph/editor/panel";
import { AttendeeSubmitData, FormData } from "../services/calendar";
import { useProjectCalendarIdUpdate } from "../services/firebase";
import { generateDescriptionFields, getTimeList } from "../services/utils";
import { useAnalyticsContext } from "../screens/Providers/AnalyticsProvider";
import { useApplicationContext } from "../screens/Providers/ApplicationContextProvider";
import { useIssueContext } from "../screens/Providers/IssueContextProvider";
import { Attendee, FetchedAttendee } from "../types";
import { useAttendeesContext } from "../screens/Providers/AttendeesProvider";
import { useDescriptionFieldsContext } from "../screens/Providers/DescriptionFieldsContextProvider";
import { DayOfWeek } from "../services/recurrence-utils";
import { DefaultSectionMessage } from "./messages/DefaultSectionMessage";
import { ServerErrorMessage } from "./messages/ServerErrorMessage";
import { useCalendarsContext } from "./CalendarsDataProvider";
import { TimeZoneSelect } from "./TimeZoneSelect";
import UserPicker from "./UserPicker";
import { DescriptionFormField } from "./DescriptionFormField";
import { RecurrenceSelect } from "./recurrence/RecurrenceSelect";
import { CustomRecurrenceDialog } from "./recurrence/CustomRecurrenceDialog";
import useRecurrencePresets from "./recurrence/useRecurrencePresets";

const EventButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 8px;
  margin-top: 10px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: flex-end;

  > div {
    width: 30%;
  }

  > div:first-child {
    width: calc(70% - 12px);
  }

  > div + div {
    margin-left: 12px;
  }
`;

export const CheckboxRow = styled.div`
  display: flex;
  align-items: center;
`;
const commonTooltipMessage = "Enter an email or search for Jira user.";

interface IProps {
  initialValues: FormData;
  onSubmit: (event: FormData) => Promise<unknown>;
  setMessage: Dispatch<SetStateAction<JSX.Element | null>>;
  onCancel?: () => void;
  editMode?: boolean;
}

export const CreateEventForm: FC<IProps> = ({
  initialValues,
  onSubmit,
  setMessage,
  onCancel,
  editMode,
}): ReactElement => {
  const { issue } = useIssueContext();
  const analytics = useAnalyticsContext();
  const { calendars, projectCalendar } = useCalendarsContext();
  const { isJiraAdmin } = useApplicationContext();
  const updateProjectCalendarId = useProjectCalendarIdUpdate(
    issue.fields.project.key
  );

  const writeableCalendarsList = calendars.filter(
    ({ accessRole }) => accessRole === "writer" || accessRole === "owner"
  );
  const [isAllDayOn, setIsAllDay] = useState(
    !(initialValues.fromTime || initialValues.toTime)
  );
  const [isDescriptionSelected, setDescription] = useState(
    Boolean(initialValues.description)
  );
  const [fromDate, setFromDate] = useState<string>(initialValues.fromDate);
  const [fromTime, setFromTime] = useState(initialValues.fromTime);
  const [toDate, setToDate] = useState<string>(initialValues.toDate);
  const [toTime, setToTime] = useState(initialValues.toTime);

  const [hasCreateButtonBeenClicked, setHasCreateButtonBeenClicked] =
    useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [summary, setSummary] = useState(initialValues.summary);
  const [eventCalendar, setEventCalendar] = useState(
    calendars.find((cal) => cal.id === initialValues.calendarId) ||
      projectCalendar
  );

  const [useDefaultTimeZone, setUseDefaultTimeZone] = useState(
    initialValues.timeZone === undefined ||
      initialValues.timeZone === eventCalendar.timeZone
  );
  const [selectedTimeZone, setSelectedTimeZone] = useState<string | undefined>(
    initialValues.timeZone
  );

  const [isCalendarSelectDisabled, setIsCalendarSelectDisabled] =
    useState(false);

  const [weekStartDay, setWeekStartDay] = useState<DayOfWeek>(0);

  const allTimesList = useMemo(() => getTimeList(), []);

  const { attendees: fetchedAttendees, fetchAllUers } = useAttendeesContext();
  const [attendees, setAttendees] = useState<Attendee[]>(
    (initialValues.attendees as FetchedAttendee[]).map(
      (attendee) => fetchedAttendees[attendee.email]
    )
  );

  const [areDescriptionFieldsEnabled, setDescriptionFieldsEnabled] = useState(
    !editMode
  );

  const { descriptionFieldsIds, issueFields } = useDescriptionFieldsContext();

  const [recurrence, setRecurrence] = useState<string | undefined>(
    initialValues.recurrence
  );

  const { recurrencePreset, setRecurrencePreset, recurrencePresets } =
    useRecurrencePresets({
      recurrence,
      fromDate,
      fromTime,
      timeZone: selectedTimeZone,
    });

  const [isCustomRecurrenceDialogOpen, setIsCustomRecurrenceDialogOpen] =
    useState(false);

  const guestSelectTooltipMessage = `${commonTooltipMessage} ${
    fetchAllUers
      ? ""
      : isJiraAdmin
      ? "Only users with email visibility set to “Anyone” in profile settings will be displayed. Consider changing the global app settings to allow inviting any Jira user."
      : "Only users with email visibility set to “Anyone” in profile settings will be displayed. Contact jira admin to change global app settings to allow inviting any Jira user."
  }`;

  useEffect(() => {
    if (editMode) {
      setAttendees((prev) =>
        prev.map((attendee) =>
          attendee.email && fetchedAttendees[attendee.email]
            ? fetchedAttendees[attendee.email]
            : attendee
        )
      );
    }
  }, [editMode, fetchedAttendees]);

  interface SubmittedFormData extends FormData {
    [key: string]:
      | string
      | boolean
      | { label: string; value: string }
      | undefined
      | AttendeeSubmitData[];
  }

  const validateOnSubmit = (data: SubmittedFormData) => {
    return ["summary", "toDate", "toTime", "fromTime", "fromDate"].reduce(
      (errors, key) => {
        if (isAllDayOn && ["toTime", "fromTime"].includes(key)) return errors;
        if (!data[key]) {
          return { ...errors, [key]: true };
        } else {
          return errors;
        }
      },
      {}
    );
  };

  const getTimeOptions = (end?: boolean): string[] => {
    if (end && fromDate === toDate && fromTime) {
      const timeIndex = allTimesList.findIndex((t) => t >= fromTime);
      return timeIndex
        ? allTimesList.slice(timeIndex, allTimesList.length)
        : allTimesList;
    }

    return allTimesList;
  };

  useEffect(() => {
    if (!hasCreateButtonBeenClicked) return setIsButtonDisabled(false);
    if (!fromDate?.length || !toDate.length || !summary.length)
      return setIsButtonDisabled(true);
    if (
      !isAllDayOn &&
      (!(fromTime && fromTime.length) || !(toTime && toTime.length))
    )
      return setIsButtonDisabled(true);
    return setIsButtonDisabled(false);
  }, [
    hasCreateButtonBeenClicked,
    fromDate,
    toDate,
    isAllDayOn,
    fromTime,
    toTime,
    summary,
  ]);

  const validateRequired = (value: string | undefined) =>
    hasCreateButtonBeenClicked && !value?.length ? "error" : undefined;

  const onCalendarSelectChange = async (option: OptionType | null) => {
    setEventCalendar(
      calendars.find((cal) => cal.id === option?.value) || projectCalendar
    );
    if (editMode || option?.value === eventCalendar.id) return;
    try {
      const newCalendarId = option?.value.toString();
      setIsCalendarSelectDisabled(true);
      setIsButtonDisabled(true);
      await updateProjectCalendarId(option?.value.toString() || "");
      void analytics.activeUse.defaultCalendarChanged(
        { newCalendarId },
        { calendarId: projectCalendar.id }
      );
      setMessage(
        <DefaultSectionMessage
          onClose={() => setMessage(null)}
          appearance="success"
          title="Default calendar changed"
        >
          <p>
            The default calendar for{" "}
            <strong>{issue.fields.project.name}</strong> project has been
            updated.
          </p>
        </DefaultSectionMessage>
      );
    } catch (err) {
      setMessage(<ServerErrorMessage />);
      console.log(err);
    } finally {
      setIsCalendarSelectDisabled(false);
      setIsButtonDisabled(false);
    }
  };

  const onToDateChange = (value: string) => {
    setToDate(value);
    if (value.length) {
      const isFromDateInvalid = isBefore(new Date(value), new Date(fromDate));

      if (isFromDateInvalid) setFromDate(value);

      if (!isAllDayOn && toTime && fromTime) {
        const isToTimeInvalid =
          value === fromDate && toTime.length && toTime < fromTime;
        if (isToTimeInvalid) setToTime("");
      }
    }
  };

  const onFromDateChange = (value: string) => {
    setFromDate(value);
    if (value.length) {
      const isToDateInvalid = isBefore(new Date(toDate), new Date(value));

      if (isToDateInvalid) setToDate(value);
      if (!isAllDayOn && toTime && fromTime) {
        const isFromTimeInvalid =
          value === toDate && toTime.length && toTime < fromTime;
        if (isFromTimeInvalid) setFromTime("");
      }
    }
  };

  const onToTimeChange = (value: string) => {
    setToTime(value);
    if (!isAllDayOn && fromTime) {
      const isFromTimeInvalid =
        value.length &&
        toDate.length &&
        toDate === fromDate &&
        value < fromTime;
      if (isFromTimeInvalid) setFromTime("");
    }
  };

  const onFromTimeChange = (value: string) => {
    setFromTime(value);
    if (!isAllDayOn && toTime) {
      const isToTimeInvalid =
        value.length && toDate.length && toDate === fromDate && toTime < value;
      if (isToTimeInvalid) setToTime("");
    }
  };

  const checkAndSubmitData = async (data: SubmittedFormData) => {
    const validationResult = validateOnSubmit(data);
    if (Object.keys(validationResult).length)
      return Promise.resolve(validationResult);
    const descriptionFields = areDescriptionFieldsEnabled
      ? generateDescriptionFields(descriptionFieldsIds, issue, issueFields)
      : "";
    const customDescription = isDescriptionSelected ? data.description : "";
    const description = !initialValues.description
      ? descriptionFields + customDescription
      : customDescription;
    const requestData = {
      ...data,
      timeZone: selectedTimeZone || eventCalendar.timeZone,
      recurrence,
      calendarId: eventCalendar.id,
      description,
      attendees: attendees.map(({ accountId, email }) => ({
        accountId,
        email,
      })),
    };
    await onSubmit(requestData);
  };

  useEffect(() => {
    AP.user.getLocale((locale) => {
      setWeekStartDay(getWeekStartByLocale(locale));
    });
  }, []);

  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLFormElement>) => {
    const isSubmitButton =
      event.target instanceof HTMLButtonElement &&
      event.target.type === "submit";
    const isTextAreaElement = event.target instanceof HTMLTextAreaElement;
    if (event.key === "Enter" && !(isSubmitButton || isTextAreaElement)) {
      event.preventDefault();
    }
  }, []);

  return (
    <Wrapper>
      <Form<SubmittedFormData> onSubmit={checkAndSubmitData}>
        {({ formProps, submitting }) => (
          <form {...formProps} onKeyDown={handleKeyDown}>
            <FormSection>
              <div>
                <Field
                  aria-required={true}
                  name="summary"
                  label="Event title"
                  defaultValue={summary}
                  validate={validateRequired}
                >
                  {({ fieldProps }) => (
                    <TextField
                      autoComplete="off"
                      {...fieldProps}
                      onChange={(event: FormEvent<HTMLInputElement>) => {
                        const target = event.target as HTMLInputElement;
                        setSummary(target.value);
                      }}
                      value={summary}
                      testId={`summary-${editMode ? "update" : "create"}-input`}
                    />
                  )}
                </Field>

                <Field<ValueType<OptionType>>
                  aria-required={true}
                  name="calendar"
                  id="calendar"
                  label="Google calendar"
                  isDisabled={isCalendarSelectDisabled}
                >
                  {({ fieldProps: { id, ...rest } }) => (
                    <Fragment>
                      <div>
                        <Select
                          id={`${id}-select`}
                          options={writeableCalendarsList.map((calendar) => ({
                            label: calendar.summary,
                            value: calendar.id,
                          }))}
                          {...rest}
                          value={{
                            label: eventCalendar.summary,
                            value: eventCalendar.id,
                          }}
                          onChange={(event) => {
                            void onCalendarSelectChange(event);
                          }}
                        />
                      </div>
                    </Fragment>
                  )}
                </Field>
                <FlexRow>
                  <Field
                    name="fromDate"
                    label="From"
                    defaultValue={fromDate}
                    validate={validateRequired}
                  >
                    {({ fieldProps }) => (
                      <DatePicker
                        {...fieldProps}
                        dateFormat="EEEE, MMMM DD"
                        onChange={onFromDateChange}
                        value={fromDate}
                        weekStartDay={weekStartDay}
                      />
                    )}
                  </Field>
                  {!isAllDayOn && (
                    <div>
                      <Field
                        name="fromTime"
                        defaultValue={fromTime}
                        validate={validateRequired}
                      >
                        {({ fieldProps }) => (
                          <TimePicker
                            {...fieldProps}
                            timeIsEditable
                            onChange={onFromTimeChange}
                            times={getTimeOptions()}
                            value={fromTime}
                          />
                        )}
                      </Field>
                    </div>
                  )}
                </FlexRow>
                <FlexRow>
                  <Field
                    name="toDate"
                    label="To"
                    defaultValue={toDate}
                    validate={validateRequired}
                  >
                    {({ fieldProps }) => (
                      <DatePicker
                        {...fieldProps}
                        dateFormat="EEEE, MMMM DD"
                        onChange={onToDateChange}
                        value={toDate}
                        weekStartDay={weekStartDay}
                      />
                    )}
                  </Field>
                  {!isAllDayOn && (
                    <Field
                      name="toTime"
                      defaultValue={toTime}
                      validate={validateRequired}
                    >
                      {({ fieldProps }) => (
                        <TimePicker
                          {...fieldProps}
                          timeIsEditable
                          onChange={onToTimeChange}
                          times={getTimeOptions(true)}
                          value={toTime}
                        />
                      )}
                    </Field>
                  )}
                </FlexRow>
                <CheckboxRow>
                  <CheckboxField name="allDay" defaultIsChecked={isAllDayOn}>
                    {({ fieldProps }) => (
                      <Checkbox
                        {...fieldProps}
                        label="All day"
                        isChecked={isAllDayOn}
                        onChange={() => setIsAllDay((e) => !e)}
                      />
                    )}
                  </CheckboxField>
                </CheckboxRow>
                <RecurrenceSelect
                  recurrence={recurrence}
                  options={recurrencePresets}
                  selected={recurrencePreset}
                  onRecurrencePresetChanged={setRecurrencePreset}
                  onRecurrenceSelected={setRecurrence}
                  onCustomRecurrenceDialogOpened={() =>
                    setIsCustomRecurrenceDialogOpen(true)
                  }
                />
                {isCustomRecurrenceDialogOpen && (
                  <CustomRecurrenceDialog
                    fromDate={fromDate}
                    weekStartDay={weekStartDay}
                    recurrence={recurrence}
                    onRecurrenceChanged={setRecurrence}
                    recurrencePresets={recurrencePresets}
                    onRecurrencePresetChanged={setRecurrencePreset}
                    onCloseDialog={() => setIsCustomRecurrenceDialogOpen(false)}
                  />
                )}
                <CheckboxRow>
                  <CheckboxField name="defaultTimezone">
                    {({ fieldProps }) => (
                      <Checkbox
                        {...fieldProps}
                        isDisabled={isAllDayOn}
                        label="Use calendar default timezone"
                        isChecked={isAllDayOn ? true : useDefaultTimeZone}
                        onChange={() => setUseDefaultTimeZone((e) => !e)}
                      />
                    )}
                  </CheckboxField>
                </CheckboxRow>
                {!useDefaultTimeZone && !isAllDayOn && (
                  <TimeZoneSelect
                    calendar={eventCalendar}
                    selectedTimeZone={selectedTimeZone}
                    onTimeZoneChange={setSelectedTimeZone}
                  />
                )}

                <Field<string>
                  aria-required={false}
                  name="guests"
                  id="guests"
                  label={
                    <AddGuestsLabelWrapper>
                      <div>Add guests</div>
                      <TooltipWrapper>
                        <Tooltip content={guestSelectTooltipMessage}>
                          <EditorPanelIcon label="" />
                        </Tooltip>
                      </TooltipWrapper>
                    </AddGuestsLabelWrapper>
                  }
                >
                  {() => (
                    <UserPicker
                      setAttendees={setAttendees}
                      attendees={attendees}
                      fetchAllUers={fetchAllUers}
                    />
                  )}
                </Field>

                <DescriptionFormField
                  isCustomDescriptionSelected={isDescriptionSelected}
                  onCustomDescriptionSelect={() =>
                    setDescription((prev) => !prev)
                  }
                  customDescriptionValue={initialValues.description}
                  areDescriptionFieldsEnabled={areDescriptionFieldsEnabled}
                  onDescriptionFieldsEnabledChange={() =>
                    setDescriptionFieldsEnabled((prev) => !prev)
                  }
                  enableAutomationCheckboxes={!initialValues.description}
                />
              </div>
            </FormSection>
            <EventButtons>
              {editMode && (
                <Button
                  appearance="subtle"
                  iconBefore={<ArrowLeftCircleIcon label="Back" />}
                  onClick={onCancel}
                >
                  Back
                </Button>
              )}
              <LoadingButton
                type="submit"
                appearance="primary"
                isLoading={submitting}
                onClick={() => setHasCreateButtonBeenClicked(true)}
                isDisabled={isButtonDisabled || isCustomRecurrenceDialogOpen}
                testId={`${editMode ? "update" : "create"}-event-button`}
              >
                {editMode ? "Save" : "Create"}
              </LoadingButton>
            </EventButtons>
          </form>
        )}
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const AddGuestsLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TooltipWrapper = styled.div`
  div {
    display: flex;
  }
`;
