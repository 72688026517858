import "@atlaskit/css-reset";
import "../main.css";
import React, { useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import { SignInScreen } from "../../screens/SignInScreen";
import { FirebaseProvider } from "../../firebase/firebase";
import { LoadingPage } from "../../components/LoadingPage";
import { Main } from "../../screens/Main";
import { SuccessfulLoginMessage } from "../../components/messages/SuccessfulLoginMessage";
import useIsVisibleElement, { usePrevious } from "../../services/hooks";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import {
  AnalyticsContextProvider,
  useAnalyticsContext,
} from "../../screens/Providers/AnalyticsProvider";
import { ApplicationContextProvider } from "../../screens/Providers/ApplicationContextProvider";
import { IssueContextProvider } from "../../screens/Providers/IssueContextProvider";
import { FeedbackProvider } from "../../components/FeedbackProvider";
import { LicenseChecker } from "../../components/LicenseChecker";
import { useIsAuthorized } from "../../services/firebase";
import { KeenClientContextProvider } from "../../screens/Providers/KeenClientProvider";
import { ProjectContextProvider } from "../../screens/Providers/ProjectContextProvider";
import { ResizeContext } from "../../components/ResizeContext";

interface AppLoaderProps {
  children?: React.ReactNode;
}

function App() {
  const analytics = useAnalyticsContext();
  const { status, data: isAuthorized } = useIsAuthorized();

  const [isSuccess, setSuccess] = useState(false);
  const prev = usePrevious(isAuthorized);

  useEffect(() => {
    if (status === "success") {
      void analytics.activeUse.appOpened({
        accountConnected: Boolean(isAuthorized),
      });
    }
  }, [status]);

  useEffect(() => {
    if (prev === null && isAuthorized && !isSuccess) {
      void analytics.activeUse.accountConnected();
      setSuccess(true);
    }
  }, [prev, isAuthorized, isSuccess]);

  function onClose() {
    setSuccess(false);
  }

  if (status === "loading") {
    return <LoadingPage />;
  } else {
    return (
      <>
        {!isAuthorized ? (
          <SignInScreen />
        ) : (
          <Main>
            <SuccessfulLoginMessage isSuccess={isSuccess} onClose={onClose} />
          </Main>
        )}
      </>
    );
  }
}

function AppLoader({ children }: AppLoaderProps) {
  const element = document.getElementById("app");
  const isVisible = useIsVisibleElement(element);
  if (isVisible) {
    return <>{children}</>;
  } else {
    return null;
  }
}

ReactDOM.render(
  <ResizeContext>
    <ErrorBoundary>
      <FeedbackProvider>
        <ApplicationContextProvider>
          <ProjectContextProvider>
            {(project) => (
              <LicenseChecker>
                <IssueContextProvider>
                  {({ issue }) => (
                    <AppLoader>
                      <KeenClientContextProvider
                        issue={issue}
                        projectType={project?.projectTypeKey}
                      >
                        <FirebaseProvider>
                          <AnalyticsContextProvider>
                            <App />
                          </AnalyticsContextProvider>
                        </FirebaseProvider>
                      </KeenClientContextProvider>
                    </AppLoader>
                  )}
                </IssueContextProvider>
              </LicenseChecker>
            )}
          </ProjectContextProvider>
        </ApplicationContextProvider>
      </FeedbackProvider>
    </ErrorBoundary>
  </ResizeContext>,
  document.getElementById("app")
);
