import KeenTracking from "keen-tracking";

export const PROJECT_ID = process.env.KEEN_PROJECT_ID as string;
export const WRITE_KEY = process.env.KEEN_WRITE_KEY as string;

interface TrackingProperties {
  [key: string]: string | boolean | number | undefined;
}

export interface Event {
  eventInfo: {
    eventName: string;
    context: TrackingProperties;
    parameters: TrackingProperties;
  };
}

export enum EventCategories {
  ActiveUse = "active use",
  PassiveUse = "passive use",
  Configure = "configure",
  Error = "error",
}

enum ActiveUseEventNames {
  AppOpened = "appOpened",
  ConnectAccountClicked = "connectAccountClicked",
  AccountConnected = "accountConnected",
  DisconnectAccountClicked = "disconnectAccountClicked",
  AccountDisconnected = "accountDisconnected",
  CreateTabClicked = "createTabClicked",
  EventsTabClicked = "eventsTabClicked",
  SettingsTabClicked = "settingsTabClicked",
  EventCreated = "eventCreated",
  EditEventClicked = "editEventClicked",
  EventEdited = "eventEdited",
  EventDeleted = "eventDeleted",
  DefaultCalendarChanged = "defaultCalendarChanged",
  FeedbackFormOpened = "feedbackFormOpened",
  ProjectSettingsOpened = "projectSettingsOpened",
  FeedbackSubmitted = "feedbackSubmitted",
  HideAppToggleSwitched = "hideAppToggleSwitched",
}

enum ConfigureEventNames {
  GlobalConfigOpened = "globalConfigOpened",
  AnalyticsConfigChanged = "analyticsConfigChanged",
}

enum ErrorEventNames {
  ThirdPartyCookiesError = "thirdPartyCookiesError",
}

type ActiveUseEvents = Record<
  ActiveUseEventNames,
  (
    parameters?: TrackingProperties,
    context?: TrackingProperties
  ) => Promise<{ created: boolean }> | void
>;

type ConfigureEvents = Record<
  ConfigureEventNames,
  (
    parameters?: TrackingProperties,
    context?: TrackingProperties
  ) => Promise<{ created: boolean }> | void
>;

type ErrorEvents = Record<
  ErrorEventNames,
  (
    parameters?: TrackingProperties,
    context?: TrackingProperties
  ) => Promise<{ created: boolean }> | void
>;

export interface Events {
  activeUse: ActiveUseEvents;
  configure: ConfigureEvents;
  error: ErrorEvents;
}

export class Keen extends KeenTracking {
  constructor(projectId: string, writeKey: string) {
    super({ projectId, writeKey });
  }

  createEvent(
    category: EventCategories,
    eventName: ActiveUseEventNames | ConfigureEventNames | ErrorEventNames
  ) {
    return async (
      parameters?: TrackingProperties,
      context?: TrackingProperties
    ) =>
      this.recordEvent(category, {
        eventInfo: {
          eventName,
          context,
          parameters,
        },
      });
  }
}

const client = new Keen(PROJECT_ID, WRITE_KEY);

export const events: Events = {
  activeUse: {
    appOpened: client.createEvent(
      EventCategories.ActiveUse,
      ActiveUseEventNames.AppOpened
    ),
    connectAccountClicked: client.createEvent(
      EventCategories.ActiveUse,
      ActiveUseEventNames.ConnectAccountClicked
    ),
    accountConnected: client.createEvent(
      EventCategories.ActiveUse,
      ActiveUseEventNames.AccountConnected
    ),
    disconnectAccountClicked: client.createEvent(
      EventCategories.ActiveUse,
      ActiveUseEventNames.DisconnectAccountClicked
    ),
    accountDisconnected: client.createEvent(
      EventCategories.ActiveUse,
      ActiveUseEventNames.AccountDisconnected
    ),
    createTabClicked: client.createEvent(
      EventCategories.ActiveUse,
      ActiveUseEventNames.CreateTabClicked
    ),
    eventsTabClicked: client.createEvent(
      EventCategories.ActiveUse,
      ActiveUseEventNames.EventsTabClicked
    ),
    settingsTabClicked: client.createEvent(
      EventCategories.ActiveUse,
      ActiveUseEventNames.SettingsTabClicked
    ),
    eventCreated: client.createEvent(
      EventCategories.ActiveUse,
      ActiveUseEventNames.EventCreated
    ),
    editEventClicked: client.createEvent(
      EventCategories.ActiveUse,
      ActiveUseEventNames.EditEventClicked
    ),
    eventEdited: client.createEvent(
      EventCategories.ActiveUse,
      ActiveUseEventNames.EventEdited
    ),
    eventDeleted: client.createEvent(
      EventCategories.ActiveUse,
      ActiveUseEventNames.EventDeleted
    ),
    defaultCalendarChanged: client.createEvent(
      EventCategories.ActiveUse,
      ActiveUseEventNames.DefaultCalendarChanged
    ),
    feedbackFormOpened: client.createEvent(
      EventCategories.ActiveUse,
      ActiveUseEventNames.FeedbackFormOpened
    ),
    feedbackSubmitted: client.createEvent(
      EventCategories.ActiveUse,
      ActiveUseEventNames.FeedbackSubmitted
    ),
    projectSettingsOpened: client.createEvent(
      EventCategories.ActiveUse,
      ActiveUseEventNames.ProjectSettingsOpened
    ),
    hideAppToggleSwitched: client.createEvent(
      EventCategories.ActiveUse,
      ActiveUseEventNames.HideAppToggleSwitched
    ),
  },
  configure: {
    globalConfigOpened: client.createEvent(
      EventCategories.Configure,
      ConfigureEventNames.GlobalConfigOpened
    ),
    analyticsConfigChanged: client.createEvent(
      EventCategories.Configure,
      ConfigureEventNames.AnalyticsConfigChanged
    ),
  },
  error: {
    thirdPartyCookiesError: client.createEvent(
      EventCategories.Error,
      ErrorEventNames.ThirdPartyCookiesError
    ),
  },
};

export default client;
