import React, {
  SetStateAction,
  useState,
  Dispatch,
  useEffect,
  useRef,
} from "react";
import Tabs, { Tab, TabList, TabPanel } from "@atlaskit/tabs";
import styled from "styled-components";
import SettingsIcon from "@atlaskit/icon/glyph/settings";
import Button from "@atlaskit/button";
import Tooltip from "@atlaskit/tooltip";
import FeedbackIcon from "@atlaskit/icon/glyph/feedback";
import Badge from "@atlaskit/badge";
import Spinner from "@atlaskit/spinner";
import { Screen, scrollToRef } from "../services/utils";
import { LoadingPage } from "../components/LoadingPage";
import { useCalendarsContext } from "../components/CalendarsDataProvider";
import { CreateEventTab } from "./CreateEventTab";
import { EventsDataProvider } from "./EventsDataProvider";
import { EventsTab } from "./EventsTab";
import { useIssueContext } from "./Providers/IssueContextProvider";
import { useAnalyticsContext } from "./Providers/AnalyticsProvider";

interface MainTabsProps {
  children?: React.ReactNode;
  setScreen: Dispatch<SetStateAction<Screen>>;
}

const Panel = styled.div`
  width: 100%;
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 0 16px;
  gap: 12px;
`;

const EventsListWrapper = styled.div`
  width: 100%;
`;

const EventsTabTitle = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const LoadingWrapper = styled.div`
  padding: 10px;
`;

export function MainTabs({ children, setScreen }: MainTabsProps) {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [message, setMessage] = useState<JSX.Element | null>(null);
  const { issue } = useIssueContext();
  const { calendars } = useCalendarsContext();
  const analytics = useAnalyticsContext();
  const mainTabsWrapper = useRef<HTMLDivElement>(null);

  function onTabChange(index: number, eventsNumber: number) {
    setMessage(null);
    setSelectedTab(index);
    if (index === 0) {
      void analytics.activeUse.createTabClicked({ eventsNumber });
    } else {
      void analytics.activeUse.eventsTabClicked({ eventsNumber });
    }
  }

  useEffect(() => {
    if (message) scrollToRef(mainTabsWrapper);
  }, [message]);

  return (
    <div ref={mainTabsWrapper}>
      <EventsDataProvider issueKey={issue.key}>
        {({ events, refreshEvents, loadingEvents }) => (
          <Tabs
            onChange={(index) => onTabChange(index, events.length)}
            selected={selectedTab}
            id="default"
          >
            <TabList>
              <Tab>Create event</Tab>
              <Tab>
                <EventsTabTitle data-testid="events-tab">
                  Events
                  {loadingEvents && <Spinner size={"medium"} />}
                  {!loadingEvents && events?.length > 0 && (
                    <Badge appearance="primary">{events?.length || 0}</Badge>
                  )}
                </EventsTabTitle>
              </Tab>
              <Icons>
                <Tooltip content="Feedback">
                  <Button
                    spacing="none"
                    iconBefore={<FeedbackIcon label="Feedback" />}
                    appearance="subtle"
                    onClick={() => setScreen(Screen.FEEDBACK)}
                  />
                </Tooltip>
                <Tooltip content="Settings">
                  <Button
                    spacing="none"
                    iconBefore={<SettingsIcon label="Settings" />}
                    appearance="subtle"
                    onClick={() => {
                      setScreen(Screen.SETTINGS);
                      void analytics.activeUse.settingsTabClicked();
                    }}
                  />
                </Tooltip>
              </Icons>
            </TabList>
            <TabPanel>
              <Panel>
                {children}
                {selectedTab === 0 && message}
                <CreateEventTab
                  issue={issue}
                  setSelectedTab={setSelectedTab}
                  setMessage={setMessage}
                  refreshEvents={refreshEvents}
                />
              </Panel>
            </TabPanel>
            <TabPanel>
              <EventsListWrapper>
                {selectedTab === 1 && message}
                {loadingEvents ? (
                  <LoadingWrapper>
                    <LoadingPage />
                  </LoadingWrapper>
                ) : (
                  <EventsTab
                    events={events}
                    calendars={calendars}
                    setSelectedTab={setSelectedTab}
                    setMessage={setMessage}
                    refreshEvents={refreshEvents}
                  />
                )}
              </EventsListWrapper>
            </TabPanel>
          </Tabs>
        )}
      </EventsDataProvider>
    </div>
  );
}
