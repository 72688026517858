import React, { useState, useEffect, useContext } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { JiraIssueResponse } from "../../services/jira-api";
import { getLicense, getLicenseType } from "../../services/license";
import keenClient, { Events, events, Keen } from "../../services/analytics";
import { getProjectTypeKeyValue } from "../../services/utils";
import { useApplicationContext } from "./ApplicationContextProvider";

interface KeenClientContextData {
  events: Events;
  keenClient: Keen;
}

const KeenClientContext = React.createContext<
  KeenClientContextData | undefined
>(undefined);

interface KeenClientContextProps {
  children?: React.ReactNode;
  issue?: JiraIssueResponse;
  projectType?: string;
}

export function KeenClientContextProvider({
  children,
  issue,
  projectType,
}: KeenClientContextProps) {
  const { clientKey, userAccountId, hostBaseUrl, addonKey, jira } =
    useApplicationContext();

  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    void (async () => {
      const [fp, { license, version }] = await Promise.all([
        FingerprintJS.load(),
        getLicense(addonKey),
      ]);
      const { visitorId } = await fp.get();
      keenClient.extendEvents(() => {
        return {
          hostInfo: { baseUrl: hostBaseUrl, clientKey },
          appInfo: {
            version,
            licenseType: getLicenseType(license),
            evaluation: license?.evaluation,
            licenseId: license?.supportEntitlementNumber,
            entitlementId: license?.entitlementId,
          },
          clientInfo: {
            fingerprint: visitorId,
          },
          eventInfo: {
            context: {
              userId: userAccountId,
              issueId: issue?.id,
              projectId: jira?.project?.id || issue?.fields.project.id,
              projectType: projectType
                ? getProjectTypeKeyValue(projectType)
                : undefined,
            },
          },
        };
      });

      setInitialized(true);
    })();
  }, []);

  return initialized ? (
    <KeenClientContext.Provider
      value={{ events: events, keenClient: keenClient }}
    >
      {children}
    </KeenClientContext.Provider>
  ) : null;
}

export function useKeenClientContext() {
  const context = useContext(KeenClientContext);
  if (!context) {
    throw new Error(
      "Keen Client context not initialized, probably context provider is missing"
    );
  }
  return context;
}
