import React, { useState, useEffect, useContext } from "react";
import KeenTracking from "keen-tracking";
import { useConfiguration } from "../../services/firebase";
import { Events, Event } from "../../services/analytics";
import { useKeenClientContext } from "./KeenClientProvider";

const isProduction = process.env.NODE_ENV === "production";

const AnalyticsContext = React.createContext<Events | undefined>(undefined);

interface AnalyticsContextProps {
  children?: React.ReactNode;
}

export function AnalyticsContextProvider({ children }: AnalyticsContextProps) {
  const { status, data } = useConfiguration();
  const configuration = data || { isAnalyticsEnabled: true };

  const { events, keenClient } = useKeenClientContext();
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (status === "success") {
      // @ts-ignore
      KeenTracking.enabled = configuration.isAnalyticsEnabled && isProduction;
      //
      if (!isProduction && configuration.isAnalyticsEnabled) {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        keenClient.on("recordEvent", (category: string, event: Event) => {
          console.debug(
            "Tracking event",
            `${category}/${event.eventInfo.eventName}`,
            event
          );
        });
      }
      setInitialized(true);
    }
  }, [status]);
  return initialized ? (
    <AnalyticsContext.Provider value={events}>
      {children}
    </AnalyticsContext.Provider>
  ) : null;
}

export function useAnalyticsContext() {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error(
      "Analytics context not initialized, probably context provider is missing"
    );
  }
  return context;
}
