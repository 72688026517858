export const getLicense = async (addonKey: string) => {
  const result = await AP.request({
    url: `/rest/atlassian-connect/1/addons/${addonKey}`,
    contentType: "application/json",
  });
  return JSON.parse(result.body) as AddonInfo;
};

interface AddonInfo {
  version: string;
  license: License;
}

interface License {
  entitlementId?: string;
  supportEntitlementNumber?: string;
  evaluation: boolean;
}

export function getLicenseType(license?: License) {
  if (!license) {
    return "No license";
  }
  if (license.evaluation === true) {
    return "Evaluation license";
  }
  if (license.evaluation === false) {
    return "Paid license";
  }
  return "Unknown license";
}
