import React, { Dispatch, SetStateAction, useState } from "react";
import { formatInTimeZone } from "date-fns-tz";
import { addHours } from "date-fns";
import { FormData, createEvent } from "../services/calendar";
import { useCalendarsContext } from "../components/CalendarsDataProvider";
import { DefaultSectionMessage } from "../components/messages/DefaultSectionMessage";
import { CreateEventForm } from "../components/EventForm";
import {
  getClosestTime,
  getErrorMessage,
  prepareGoogleDate,
} from "../services/utils";
import { JiraIssueResponse } from "../services/jira-api";
import { prepareEndDate } from "../services/all-day-event-utils";
import { CalendarErrorMessage } from "../components/messages/CalendarErrorMessage";
import { useAnalyticsContext } from "./Providers/AnalyticsProvider";

interface CreateEventTabProps {
  setSelectedTab: Dispatch<SetStateAction<number>>;
  setMessage: Dispatch<SetStateAction<JSX.Element | null>>;
  refreshEvents: () => void;
  issue: JiraIssueResponse;
}

export const CreateEventTab = ({
  setSelectedTab,
  setMessage,
  refreshEvents,
  issue,
}: CreateEventTabProps) => {
  const [reloadTimestamp, setReloadTimestamp] = useState<number>(Date.now());
  const { projectCalendar } = useCalendarsContext();
  const analytics = useAnalyticsContext();

  const currentDate = new Date();
  const dateTimeFormatString = "yyyy-MM-dd HH:mm";
  const [initialFromDate, initialPreciseFromTime] = formatInTimeZone(
    currentDate,
    projectCalendar.timeZone,
    dateTimeFormatString
  ).split(" ");
  const initialFromTime = getClosestTime(initialPreciseFromTime);

  const [initialToDate, initialPreciseToTime] = formatInTimeZone(
    addHours(currentDate, 1),
    projectCalendar.timeZone,
    dateTimeFormatString
  ).split(" ");
  const initialToTime = getClosestTime(initialPreciseToTime);

  const initialValues: FormData = {
    summary: `${issue.key}: ${issue.fields.summary}`,
    fromDate: initialFromDate,
    toDate: initialToDate,
    fromTime: initialFromTime,
    toTime: initialToTime,
    description: "",
    allDay: false,
    attendees: [],
  };

  const onSubmit = async ({
    allDay,
    toDate,
    toTime,
    fromDate,
    fromTime,
    timeZone,
    description,
    summary,
    attendees,
    recurrence,
  }: FormData) => {
    try {
      const end = prepareGoogleDate(
        prepareEndDate(toDate, allDay, timeZone),
        timeZone,
        !allDay ? toTime : undefined
      );
      const start = prepareGoogleDate(
        fromDate,
        timeZone,
        !allDay ? fromTime : undefined
      );

      const { eventId, calendarId } = await createEvent(
        {
          description,
          summary,
          end,
          start,
          attendees,
          ...(recurrence ? { recurrence: [recurrence] } : {}),
        },
        projectCalendar.id,
        issue.key
      );
      void analytics.activeUse.eventCreated(
        {
          defaultName: initialValues.summary === summary,
          allDay,
          description: !!description,
        },
        { eventId, calendarId }
      );
      setSelectedTab(1);
      setMessage(
        <DefaultSectionMessage
          onClose={() => setMessage(null)}
          appearance="success"
          title="Event successfully created"
        />
      );
      refreshEvents();
      setReloadTimestamp(Date.now());
    } catch (err) {
      console.error(getErrorMessage(err));
      setMessage(
        <CalendarErrorMessage error={err} onClose={() => setMessage(null)} />
      );
    }
  };

  return (
    <CreateEventForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      setMessage={setMessage}
      key={reloadTimestamp}
    />
  );
};
