import React, { useState } from "react";
import { CalendarsDataProvider } from "../components/CalendarsDataProvider";
import { FeedbackFormWithAnalytics } from "../components/FeedbackFormWithAnalytics";
import { Screen } from "../services/utils";
import { Settings } from "./Settings";
import { MainTabs } from "./MainTabs";
import { useIssueContext } from "./Providers/IssueContextProvider";
import { DescriptionFieldsContextProvider } from "./Providers/DescriptionFieldsContextProvider";

interface MainProps {
  children?: React.ReactNode;
}

export function Main({ children }: MainProps) {
  const { issue } = useIssueContext();
  const [screen, setScreen] = useState<Screen>(Screen.MAIN);

  const renderScreen = (screen: Screen) => {
    switch (screen) {
      case Screen.MAIN:
        return <MainTabs setScreen={setScreen}>{children}</MainTabs>;
      case Screen.SETTINGS:
        return <Settings onNavigateBack={() => setScreen(Screen.MAIN)} />;
      case Screen.FEEDBACK:
        return (
          <FeedbackFormWithAnalytics onClose={() => setScreen(Screen.MAIN)} />
        );
      default:
        return null;
    }
  };

  return (
    <CalendarsDataProvider projectKey={issue.fields.project.key}>
      <DescriptionFieldsContextProvider>
        {renderScreen(screen)}
      </DescriptionFieldsContextProvider>
    </CalendarsDataProvider>
  );
}
