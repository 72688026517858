import React from "react";
import { useAnalyticsContext } from "../screens/Providers/AnalyticsProvider";
import { FeedbackForm } from "./FeedbackForm";

interface FeebackFormWithAnalyticsProps {
  onClose: () => void;
}

export const FeedbackFormWithAnalytics = ({
  onClose,
}: FeebackFormWithAnalyticsProps) => {
  const analytics = useAnalyticsContext();
  return <FeedbackForm onClose={onClose} analytics={analytics} />;
};
