import React, { useState } from "react";
import SectionMessage, {
  SectionMessageAction,
} from "@atlaskit/section-message";
import CheckCircleOutlineIcon from "@atlaskit/icon/glyph/check-circle-outline";
import styled from "styled-components";
import Button from "@atlaskit/button";
import ArrowLeftCircleIcon from "@atlaskit/icon/glyph/arrow-left-circle";
import { revokeApplicationAccess } from "../services/oauth";
import { LoadingPage } from "../components/LoadingPage";
import { getErrorMessage } from "../services/utils";
import { useFeedbackContext } from "../components/FeedbackProvider";
import { useCalendarsContext } from "../components/CalendarsDataProvider";
import { Label } from "../components/shared/Label";
import DescriptionFieldsSelect from "../components/DescriptionFieldsSelect";
import { useSetDescriptionFields } from "../services/firebase";
import { useDescriptionFieldsContext } from "../screens/Providers/DescriptionFieldsContextProvider";
import { useAnalyticsContext } from "./Providers/AnalyticsProvider";

const InlineMessage = styled.div`
  margin-top: 6px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  > p {
    margin: 0;
  }
`;

const SettingsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
  margin-right: 10px;
`;
const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 8px;
  margin-top: 10px;
`;

interface SettingsProps {
  onNavigateBack: () => void;
}

export function Settings({ onNavigateBack }: SettingsProps) {
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const [error, setError] = useState("");
  const analytics = useAnalyticsContext();
  const { setIsFeedbackOpen } = useFeedbackContext();
  const { primaryCalendarId } = useCalendarsContext();
  const setFields = useSetDescriptionFields();
  const { selectedValues } = useDescriptionFieldsContext();

  async function onResetToken() {
    try {
      setLoading(true);
      void analytics.activeUse.disconnectAccountClicked();
      await revokeApplicationAccess();
      void analytics.activeUse.accountDisconnected();
    } catch (error) {
      setError(getErrorMessage(error));
      setLoading(false);
    }
  }

  function onSave() {
    void setFields(selectedValues);
    onNavigateBack();
  }

  if (loading) {
    return <LoadingPage />;
  }

  return !error ? (
    <SettingsContent>
      <h3>Settings</h3>
      <div>
        <Label>Connected account</Label>
        <InlineMessage>
          <CheckCircleOutlineIcon
            label="Connected"
            primaryColor="#36B37E"
            size="large"
          />
          <p>
            You are connected to <br></br>
            {primaryCalendarId}
          </p>
          <ButtonWrapper>
            <Button
              isDisabled={confirm}
              appearance="link"
              onClick={() => {
                setConfirm(true);
              }}
            >
              Disconnect
            </Button>
          </ButtonWrapper>
        </InlineMessage>
      </div>

      {confirm && (
        <SectionMessage
          appearance="warning"
          title="Disconnect Google account"
          actions={[
            <SectionMessageAction
              key="close"
              onClick={() => void onResetToken()}
            >
              Reset token
            </SectionMessageAction>,
            <SectionMessageAction
              key="cancel"
              onClick={() => {
                setConfirm(false);
              }}
            >
              Cancel
            </SectionMessageAction>,
          ]}
        >
          <p>
            If you reset token your account will be detached from Issue Events.
          </p>
        </SectionMessage>
      )}

      <div>
        <Label>Description custom fields</Label>
        <DescriptionFieldsSelect />
      </div>

      <Footer>
        <Button
          appearance="subtle"
          iconBefore={<ArrowLeftCircleIcon label="Back" />}
          onClick={onNavigateBack}
        >
          Back
        </Button>
        <Button type="button" appearance="primary" onClick={onSave}>
          Save
        </Button>
      </Footer>
    </SettingsContent>
  ) : (
    <SectionMessage
      appearance="error"
      title="Error occurred"
      actions={[
        <SectionMessageAction
          key="try-again"
          onClick={() => void onResetToken()}
        >
          Try Again
        </SectionMessageAction>,
        <SectionMessageAction
          key="support"
          onClick={() => setIsFeedbackOpen(true)}
        >
          Support
        </SectionMessageAction>,
      ]}
    >
      <p>{error}</p>
    </SectionMessage>
  );
}
