import React, { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import {
  deleteEvent,
  updateEvent,
  createEvent,
  Event,
  FormData,
  Calendar,
} from "../services/calendar";
import {
  getErrorMessage,
  parseGoogleDate,
  prepareGoogleDate,
  getErrorStatus,
  stripGoogleEventDescription,
} from "../services/utils";
import { useIssueContext } from "../screens/Providers/IssueContextProvider";
import { useAnalyticsContext } from "../screens/Providers/AnalyticsProvider";
import { parseEndDate, prepareEndDate } from "../services/all-day-event-utils";
import { useAttendeesContext } from "../screens/Providers/AttendeesProvider";
import { DefaultSectionMessage } from "./messages/DefaultSectionMessage";
import { CreateEventForm } from "./EventForm";
import { useCalendarsContext } from "./CalendarsDataProvider";
import { EventsListPreview } from "./EventsListPreview";
import { CalendarErrorMessage } from "./messages/CalendarErrorMessage";

interface EventsListElementProps {
  event: Event;
  setMessage: Dispatch<SetStateAction<JSX.Element | null>>;
  refreshEventList: () => void;
  calendars: Calendar[];
}

export const EventsListElement = ({
  event,
  setMessage,
  refreshEventList,
  calendars,
}: EventsListElementProps) => {
  const { issue } = useIssueContext();
  const { projectCalendar } = useCalendarsContext();
  const analytics = useAnalyticsContext();
  const [editMode, setEditMode] = useState(false);
  const { attendees } = useAttendeesContext();
  const isEditable = calendars
    .filter(
      ({ accessRole }) => accessRole === "writer" || accessRole === "owner"
    )
    .map((c) => c.id)
    .includes(event.calendarId);

  const { date: initialFromDate, time: initialFromTime } = parseGoogleDate(
    event.start,
    projectCalendar.timeZone
  );

  const { date: initialToDate, time: initialToTime } = parseGoogleDate(
    event.end,
    projectCalendar.timeZone
  );

  const isAllDayOn = Boolean(event.end.date && !event.end.dateTime);

  const initialValues: FormData = {
    summary: event.summary,
    fromDate: initialFromDate,
    toDate: parseEndDate(initialToDate, isAllDayOn, event.start.timeZone),
    fromTime: initialFromTime,
    toTime: initialToTime,
    timeZone: event.start.timeZone,
    description: event.description
      ? stripGoogleEventDescription(event.description)
      : "",
    allDay: isAllDayOn,
    calendarId: event.calendarId,
    attendees:
      event.attendees?.map((attendee) => ({
        email: attendee.email,
        accountId: attendees[attendee.email]?.accountId,
      })) || [],
    recurrence: event.recurrence?.[0],
  };

  const editEvent = async ({
    allDay,
    toDate,
    toTime,
    fromDate,
    fromTime,
    timeZone,
    description,
    summary,
    calendarId,
    attendees,
    recurrence,
  }: FormData) => {
    try {
      const end = prepareGoogleDate(
        prepareEndDate(toDate, allDay, timeZone),
        timeZone,
        !allDay ? toTime : undefined
      );
      const start = prepareGoogleDate(
        fromDate,
        timeZone,
        !allDay ? fromTime : undefined
      );

      void analytics.activeUse.eventEdited(
        {
          titleEdited: event.summary !== summary,
          descriptionEdited: event.description !== description,
          fromEdited: event.start !== start,
          toEdited: event.end !== end,
          calendarIdEdited: calendarId !== event.calendarId,
          allDayEdited: isAllDayOn !== allDay,
          recurrenceEdited: recurrence,
        },
        { eventId: event.eventId, calendarId: event.calendarId }
      );

      // Calendar Change Detected => 1) Remove Old Event 2) Create New Event
      if (calendarId !== event.calendarId) {
        await deleteEvent(
          event.calendarId,
          event.eventId,
          event.eventKey,
          issue.key
        );
        await createEvent(
          {
            description,
            summary,
            end,
            start,
            attendees,
            recurrence: recurrence ? [recurrence] : undefined,
          },
          calendarId || projectCalendar.id,
          issue.key
        );
        setMessage(
          <DefaultSectionMessage
            onClose={() => setMessage(null)}
            appearance="success"
            title="The event was updated and moved to the selected calendar"
          />
        );
      } else {
        await updateEvent(
          {
            description,
            summary,
            end,
            start,
            attendees,
            recurrence: recurrence ? [recurrence] : undefined,
          },
          event.calendarId,
          event.eventId,
          issue.key
        );

        setMessage(
          <DefaultSectionMessage
            onClose={() => setMessage(null)}
            appearance="success"
            title="Event successfully updated"
          />
        );
      }
      refreshEventList();
    } catch (err) {
      console.log(getErrorMessage(err));
      if (getErrorStatus(err) === 410) {
        setMessage(
          <DefaultSectionMessage
            appearance="error"
            title="Error occurred"
            onClose={() => setMessage(null)}
          >
            Event doesn&apos;t exist anymore.
          </DefaultSectionMessage>
        );
        return refreshEventList();
      }
      setMessage(
        <CalendarErrorMessage
          error={err}
          onClose={() => setMessage(null)}
          refreshEvents={refreshEventList}
        />
      );
    }
  };

  const cancel = () => {
    setEditMode(false);
  };

  const remove = async () => {
    try {
      await deleteEvent(
        event.calendarId,
        event.eventId,
        event.eventKey,
        issue.key
      );
      void analytics.activeUse.eventDeleted();

      setMessage(
        <DefaultSectionMessage
          onClose={() => setMessage(null)}
          appearance="success"
          title="The event has been deleted"
        >
          <p data-testid="successful-deletion-message">
            Event <strong>{event.summary}</strong> has been deleted.
          </p>
        </DefaultSectionMessage>
      );
      refreshEventList();
    } catch (err) {
      console.log(getErrorMessage(err));
      if (getErrorStatus(err) === 410) {
        setMessage(null);
        return refreshEventList();
      }
      setMessage(
        <CalendarErrorMessage
          error={err}
          onClose={() => setMessage(null)}
          refreshEvents={refreshEventList}
        />
      );
    }
  };

  function onEditEventClicked() {
    setMessage(null);
    setEditMode(true);
    void analytics.activeUse.editEventClicked({}, { eventId: event.eventId });
  }

  const eventPreview = {
    ...event,
    end: prepareGoogleDate(
      parseEndDate(initialToDate, isAllDayOn, event.start.timeZone),
      projectCalendar.timeZone,
      initialToTime
    ),
  };

  if (!editMode) {
    return (
      <EventsListPreview
        event={eventPreview}
        setMessage={setMessage}
        isEditable={isEditable}
        editEvent={onEditEventClicked}
        removeEvent={() => {
          void remove();
        }}
      />
    );
  } else {
    return (
      <FormWrapper>
        <CreateEventForm
          initialValues={initialValues}
          onSubmit={editEvent}
          editMode={true}
          onCancel={cancel}
          setMessage={setMessage}
        />
      </FormWrapper>
    );
  }
};

const FormWrapper = styled.li`
  border-top: 1px solid #ccc;
  &:first-child {
    border-top: none;
  }
`;
